<template>
  <div class="home">
    <div
      :style="{
        display: pageWidth < 700 ? 'none' : 'block',
      }"
    >
      <div
        class="bg_box"
        :style="{
          height: langShow ? '1027px' : '956px',
        }"
      >
        <div
          class="bg_top_img"
          :style="{
            top: langShow ? '180px' : '120px',
          }"
        >
          <img src="../../assets/bg_top.png" alt="" />
        </div>
        <div class="title1">
          <h1
            :style="{
              fontSize: langShow ? '37px' : '50px',
            }"
          >
            {{ $t("comeBtalk") }}
          </h1>
          <h1
            :style="{
              fontSize: langShow ? '37px' : '50px',
            }"
          >
            {{ $t("title") }}
          </h1>
          <p>
            {{ $t("txt") }}
          </p>
          <el-popover
            placement="bottom"
            title="请扫码下载"
            width="200"
            trigger="click"
          >
            <div>
              <img
                style="width: 200px; height: 200px"
                src="../../assets/downEWM.apk.png"
                alt=""
              />
            </div>
            <div class="footer" slot="reference" @click="handleIndex">
              <img src="@/assets/18.png" alt="" />
              <p style="cursor: pointer">{{ $t("download") }}</p>
            </div>
          </el-popover>
        </div>

        <div
          class="title2"
          :style="{
            marginTop: langShow ? '337px' : '305px',
          }"
        >
          <h2>{{ $t("group") }}</h2>
          <p>
            {{ $t("txt2") }}
          </p>
        </div>
      </div>

      <div class="project_img">
        <img src="../../assets/project.png" alt="" />
      </div>

      <div class="start_prj">
        <h2>{{ $t("start") }}</h2>

        <div class="start_prj_boxbig">
          <div class="start_prj_box">
            <img src="../../assets/start1.png" alt="" />
            <div>
              <h3>{{ $t("step1") }}</h3>
              <p>{{ $t("stepTxt1") }}</p>
            </div>
          </div>
          <div class="start_prj_box">
            <img src="../../assets/start2.png" alt="" />
            <div>
              <h3>{{ $t("step2") }}</h3>
              <p>{{ $t("stepTxt2") }}</p>
            </div>
          </div>
          <div class="start_prj_box">
            <img src="../../assets/start3.png" alt="" />
            <div>
              <h3>{{ $t("step3") }}</h3>
              <p>{{ $t("stepTxt3") }}</p>
            </div>
          </div>
          <div class="start_prj_box">
            <img src="../../assets/start4.png" alt="" />
            <div>
              <h3>{{ $t("step4") }}</h3>
              <p>{{ $t("stepTxt4") }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="secure">
        <h2>{{ $t("safe") }}</h2>
        <p>
          {{ $t("txt3") }}
        </p>
        <img src="../../assets/secure.png" alt="" />
      </div>

      <div class="cple_prj">
        <h2>{{ $t("title2") }}</h2>
        <p>
          {{ $t("txt4") }}
        </p>

        <img src="../../assets/cple.png" alt="" />
        <h2>{{ $t("cple") }}</h2>
        <p>{{ $t("cpleTxt") }}</p>
      </div>

      <div class="join_mine">
        <h2>{{ $t("join") }}</h2>
        <h3>{{ $t("downTxt") }}</h3>

        <el-popover
          placement="bottom"
          title="请扫码下载"
          width="200"
          trigger="click"
        >
          <div>
            <img
              style="width: 200px; height: 200px"
              src="../../assets/downEWM.apk.png"
              alt=""
            />
          </div>
          <div class="footer" slot="reference" @click="handleIndex">
            <img src="@/assets/18.png" alt="" />
            <p style="cursor: pointer">{{ $t("download") }}</p>
          </div>
        </el-popover>
      </div>
    </div>

    <div
      class="page-width"
      :style="{
        display: pageWidth >= 700 ? 'none' : 'block',
      }"
    >
      <van-popup v-model="show" round closeable>
        <div style="margin: 0 10px">
          <p style="color: #000; text-align: center">请扫码下载</p>
          <img
            style="width: 200px; height: 200px"
            src="../../assets/downEWM.apk.png"
            alt=""
          />
        </div>
      </van-popup>

      <div style="width: 100vw">
        <div class="bg_top_img-img"></div>
        <div class="title1">
          <h1>{{ $t("comeBtalk") }}</h1>
          <h1>{{ $t("title") }}</h1>
          <p>{{ $t("txt") }}</p>
          <div
            style="display: flex; justify-content: center"
            @click="show = true"
          >
            <div class="footer">
              <img src="@/assets/18.png" alt="" />
              <p>{{ $t("download") }}</p>
            </div>
          </div>
        </div>

        <div class="title2">
          <h1>{{ $t("group") }}</h1>
          <p>
            {{ $t("txt2") }}
          </p>
        </div>

        <div class="project2_img">
          <img src="../../assets/project2.png" alt="" />
        </div>
      </div>

      <div class="start_prj2">
        <h2>{{ $t("start") }}</h2>

        <div class="start_prj_boxbig">
          <div class="start_prj_box" v-for="(item, i) in startList" :key="i">
            <img :src="item.imgUrl" alt="" />
            <div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.txt }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="secure">
        <div>
          <h2 style="color: #fff">{{ $t("safe") }}</h2>
          <p>
            {{ $t("txt3") }}
          </p>
        </div>
        <img src="../../assets/secure2.png" alt="" />
      </div>

      <div class="cple_prj">
        <h2>{{ $t("title2") }}</h2>
        <p>{{ $t("txt4") }}</p>

        <img src="../../assets/cple2.png" alt="" />
        <h2>{{ $t("cple") }}</h2>
        <p>{{ $t("cpleTxt") }}</p>
      </div>

      <div class="join_mine">
        <h2 style="color: #fff">{{ $t("join") }}</h2>
        <h3>{{ $t("downTxt") }}</h3>

        <div class="footer" @click="show = true">
          <img src="@/assets/18.png" alt="" />
          <p>{{ $t("download") }}</p>
        </div>
      </div>

      <div class="footer_page_width">
        <img src="@/assets/logo.png" @click="handleIndex" alt="" />
      </div>
    </div>
    <el-backtop :visibility-height="20"></el-backtop>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "Home",
  data() {
    return {
      pageWidth: 0,
      showTop: false,
      langShow: false,
      show: false,
      startList: [
        {
          imgUrl: require("../../assets/start1.png"),
          title: this.$t("step1"),
          txt: this.$t("stepTxt1"),
        },
        {
          imgUrl: require("../../assets/start2.png"),
          title: this.$t("step2"),
          txt: this.$t("stepTxt2"),
        },
        {
          imgUrl: require("../../assets/start3.png"),
          title: this.$t("step3"),
          txt: this.$t("stepTxt3"),
        },
        {
          imgUrl: require("../../assets/start4.png"),
          title: this.$t("step4"),
          txt: this.$t("stepTxt4"),
        },
      ],
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;

    let lang = localStorage.getItem("language");

    if (lang == "zh") {
      this.$i18n.locale = localStorage.getItem("language");
      this.langShow = false;
    } else if (lang == "en") {
      this.$i18n.locale = localStorage.getItem("language");
      this.langShow = true;
    }
  },

  created() {
    let lang = localStorage.getItem("language");

    if (lang == "zh") {
      this.$i18n.locale = localStorage.getItem("language");
    } else if (lang == "en") {
      this.$i18n.locale = localStorage.getItem("language");
    }
  },

  methods: {
    handleIndex() {
      this.$router.push("/");
    },
  },

  watch: {
    "$store.state.isLang": {
      handler: function (newVal, oldVal) {
        if (newVal == "English") {
          this.langShow = true;
        }
        if (newVal == "CN") {
          this.langShow = false;
        }
      },
    },
  },
};
</script>
