<template>
  <el-footer style="height: 155px !important">
    <div class="footer_hr"></div>
    <div style="display: flex; justify-content: space-between">
      <div class="footer_l">
        <img src="@/assets/logo.png" @click="handleIndex" alt="" />

        <h3>{{ $t("joinBtalk") }}</h3>
        <div class="footer_l_navlink">
          <p @click="goConFooter('/')">{{ $t("download2") }}</p>
          <p>/</p>
          <p @click="goConFooter('/contactUs')">{{ $t("contact") }}</p>
        </div>
      </div>
      <div class="footet_r" @click="goConFooter('/privacyAndService')">
        {{ $t("privacy") }}
      </div>
    </div>

    <!-- <div class="footer_record">
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              >京ICP备17038918号-1</a
            >
          </div> -->
  </el-footer>
</template>

<script>
export default {
  methods: {
    handleIndex() {
      this.$router.push("/");
    },

    goConFooter(path) {
      this.$router.push(path);
    },
  },
};
</script>
