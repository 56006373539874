import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../Index.vue";
import ContactUs from "../views/ContactUs/index.vue";
import Privacy from "../views/Privacy/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Index,
    children: [
      {
        path: "/",
        name: "index",
        components: { main: () => import("../views/downLoad/index.vue") },
      },
    ],
  },

  {
    path: "/contactUs",
    name: "contactUs",
    component: ContactUs,
  },
  {
    path: "/privacyAndService",
    name: "privacy",
    component: Privacy,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
