<template>
  <el-header>
    <div class="header_l">
      <img src="@/assets/logo.png" @click="handleIndex" alt="" />
    </div>
    <div class="header_r">
      <el-menu :default-active="onRoutes" mode="horizontal">
        <el-menu-item @click="goCon('/')" index="1">
          {{ $t("download2") }}
        </el-menu-item>
        <el-menu-item @click="goCon('/contactUs')" index="2">
          {{ $t("about") }}
        </el-menu-item>

        <el-menu-item index="6">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="CN">CN</el-dropdown-item>
              <el-dropdown-item command="English">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
      </el-menu>
    </div>
  </el-header>
</template>

<script>
export default {
  data() {
    return {
      language: "CN" || "English",
    };
  },

  mounted() {
    let lang = localStorage.getItem("language");
    if (lang == "zh") {
      localStorage.setItem("language", "zh");
      this.$i18n.locale = localStorage.getItem("language");
      this.language = "CN";
    } else if (lang == "en") {
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
      this.language = "English";
    }
  },

  computed: {
    // 动态返回对应的路由path
    onRoutes() {
      return this.$route.path;
    },
  },

  methods: {
    handleIndex() {
      this.$router.push("/");
    },

    goCon(conE) {
      if (this.$route.path === conE) {
        return;
      } else {
        this.showTop = false;
        this.$router.push(conE);
      }
    },

    handleCommand(command) {
      this.language = command;
      this.$store.state.isLang = command;

      if (this.language == "CN") {
        localStorage.setItem("language", "zh");
        this.$i18n.locale = localStorage.getItem("language");
      } else if (this.language == "English") {
        localStorage.setItem("language", "en");
        this.$i18n.locale = localStorage.getItem("language");
      }
    },
  },
};
</script>

<style>
</style>