<template>
  <div>
    <div :style="{ display: pageWidth < 700 ? 'none' : 'block' }">
      <div class="contactus" style="height: calc(100vh - 555px)">
        <el-backtop :visibility-height="20"> </el-backtop>
        <Header></Header>
        <img src="@/assets/logo.png" alt="" class="logo_img" />
        <div class="website_header">
          <h1
            :style="{ fontSize: pageWidth < 700 ? '32px' : '50px' }"
            style="margin-bottom: 52px"
          >
            {{ $t("about") }}
          </h1>
        </div>

        <div class="contact_content">
          <div class="contact_con_t" style="margin-right: 150px">
            <h2>{{ $t("contact") }}</h2>
            <p>{{ $t("please") }}</p>
            <div>
              <img src="../../assets/about1.png" alt="" />
              <p>ceo@btalk.ai</p>
            </div>
          </div>
          <div class="contact_con_t">
            <h2>{{ $t("follow") }}</h2>
            <p>{{ $t("please2") }}</p>
            <div style="margin: 0">
              <div>
                <img src="../../assets/about2.png" alt="" />
                <p>Facebook</p>
              </div>
              <div>
                <img src="../../assets/about3.png" alt="" />
                <p>Twitter</p>
              </div>
              <div>
                <img src="../../assets/about4.png" alt="" />
                <p>Telegram</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>

    <div
      class="contactus_page"
      :style="{ display: pageWidth >= 700 ? 'none' : 'block' }"
    >
      <HeaderPage></HeaderPage>

      <div class="website_header">
        <h1 style="margin-bottom: 52px">{{ $t("about") }}</h1>
      </div>

      <div class="contact_content">
        <div class="contact_con_t">
          <h2>{{ $t("contact") }}</h2>
          <p class="contact_cont_txt">{{ $t("please") }}</p>
          <div>
            <img src="../../assets/about1.png" alt="" />
            <p>ceo@btalk.ai</p>
          </div>
        </div>
        <div class="contact_con_t">
          <h2>{{ $t("follow") }}</h2>
          <p class="contact_cont_txt">{{ $t("please2") }}</p>

          <div style="margin: 0" class="contact_cont_img">
            <div>
              <img src="../../assets/about2.png" alt="" />
              <p>Facebook</p>
            </div>
            <div>
              <img src="../../assets/about3.png" alt="" />
              <p>Twitter</p>
            </div>
            <div>
              <img src="../../assets/about4.png" alt="" />
              <p>Telegram</p>
            </div>
          </div>
        </div>
      </div>

      <div class="footer_page_width">
        <img src="@/assets/logo.png" @click="handleIndex" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../component/Header";
import HeaderPage from "../../component/HeaderPage";
import Footer from "../../component/Footer";
import "./index.scss";
export default {
  components: { Header, HeaderPage, Footer },
  data() {
    return {
      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },

  methods: {
    handleIndex() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
