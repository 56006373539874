<template>
  <div>
    <div class="privacy">
      <Header :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"></Header>
      <HeaderPage
        :style="{ display: pageWidth >= 700 ? 'none' : 'flex' }"
      ></HeaderPage>
      <div style="padding-top: 10px">
        <div :class="containerClass">
          <img
            src="@/assets/logo.png"
            :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"
            alt=""
            class="logo_img"
          />
          <h2>{{ $t("privacyService.title") }}</h2>
          <h3>{{ $t("privacyService.dear") }}</h3>
          <p>{{ $t("privacyService.titleTxt1") }}</p>
          <p>{{ $t("privacyService.titleTxt2") }}</p>

          <h3>{{ $t("privacyService.title1") }}</h3>
          <p>{{ $t("privacyService.title1Txt") }}</p>

          <h3>{{ $t("privacyService.title2") }}</h3>
          <p>{{ $t("privacyService.title2Txt") }}</p>

          <h3>{{ $t("privacyService.title3") }}</h3>
          <p>{{ $t("privacyService.title3Txt") }}</p>

          <h3>{{ $t("privacyService.title4") }}</h3>
          <p>{{ $t("privacyService.title4Txt") }}</p>

          <h3>{{ $t("privacyService.title5") }}</h3>
          <p>{{ $t("privacyService.title5Txt") }}</p>

          <h3>{{ $t("privacyService.title6") }}</h3>
          <p>{{ $t("privacyService.title6Txt") }}</p>

          <h3>{{ $t("privacyService.title7") }}</h3>
          <p>{{ $t("privacyService.title7Txt") }}</p>

          <h3>{{ $t("privacyService.title8") }}</h3>
          <p>{{ $t("privacyService.title8Txt") }}</p>

          <h3>{{ $t("privacyService.title9") }}</h3>
          <p>{{ $t("privacyService.title9Txt") }}</p>

          <h3>{{ $t("privacyService.title10") }}</h3>
          <p>{{ $t("privacyService.title10Txt") }}</p>

          <h3>{{ $t("privacyService.title11") }}</h3>
          <p>{{ $t("privacyService.title11Txt") }}</p>

          <h3>{{ $t("privacyService.title12") }}</h3>
          <p>{{ $t("privacyService.title12Txt") }}</p>

          <h3>{{ $t("privacyService.title13") }}</h3>
          <p>{{ $t("privacyService.title13Txt") }}</p>

          <h3>{{ $t("privacyService.title14") }}</h3>
          <p>{{ $t("privacyService.title14Txt") }}</p>

          <h3>{{ $t("privacyService.title15") }}</h3>
          <p>{{ $t("privacyService.title15Txt") }}</p>

          <h3>{{ $t("privacyService.date") }}</h3>
          <p style="text-align: center; margin-top: 10px">
            {{ $t("privacyService.rule") }}
          </p>
          <p style="text-align: right; margin-bottom: 50px">BTalk.ai</p>

          <h2>{{ $t("privacyService2.title") }}</h2>
          <h3>{{ $t("privacyService.dear") }}</h3>

          <p>{{ $t("privacyService2.titleTxt") }}</p>

          <h3>{{ $t("privacyService2.title1") }}</h3>
          <p>{{ $t("privacyService2.title1Txt") }}</p>

          <h3>{{ $t("privacyService2.title2") }}</h3>
          <p>{{ $t("privacyService2.title2Txt") }}</p>

          <h3>{{ $t("privacyService2.title3") }}</h3>
          <p>{{ $t("privacyService2.title3Txt") }}</p>

          <h3>{{ $t("privacyService2.title4") }}</h3>
          <p>{{ $t("privacyService2.title4Txt") }}</p>

          <h3>{{ $t("privacyService2.title5") }}</h3>
          <p>{{ $t("privacyService2.title5Txt") }}</p>

          <h3>{{ $t("privacyService2.title6") }}</h3>
          <p>{{ $t("privacyService2.title6Txt") }}</p>

          <h3>{{ $t("privacyService2.title7") }}</h3>
          <p>{{ $t("privacyService2.title7Txt") }}</p>

          <h3>{{ $t("privacyService2.title8") }}</h3>
          <p>{{ $t("privacyService2.title8Txt") }}</p>

          <h3>{{ $t("privacyService2.date") }}</h3>
          <p style="text-align: center">
            {{ $t("privacyService2.rule") }}
          </p>
          <p style="text-align: right; margin-bottom: 50px">BTalk.ai</p>
        </div>
      </div>

      <div
        class="footer_page_width"
        style="position: static"
        :style="{ display: pageWidth >= 700 ? 'none' : 'flex' }"
      >
        <img src="@/assets/logo.png" @click="handleIndex" alt="" />
      </div>
    </div>
    <Footer :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"></Footer>
  </div>
</template>

<script>
import Header from "../../component/Header";
import HeaderPage from "../../component/HeaderPage";
import Footer from "../../component/Footer";
export default {
  components: { Header, HeaderPage, Footer },
  data() {
    return {
      pageWidth: 0,
    };
  },

  computed: {
    containerClass() {
      return this.pageWidth < 700 ? "privacy_con2" : "privacy_con";
    },
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },

  methods: {
    handleIndex() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
@import "./index.scss";
</style>