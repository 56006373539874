<template>
  <div>
    <div
      style="background: #000"
      :style="{
        display: pageWidth < 700 ? 'none' : 'block',
      }"
    >
      <div class="header">
        <Header></Header>
        <el-container>
          <!-- <el-main style="padding: 0; margin-top: 110px">
          <router-view name="main"
        /></el-main> -->
          <!-- @wheel="goWheel" -->
          <div style="padding: 0">
            <DownLoad />
          </div>
        </el-container>
      </div>

      <Footer></Footer>
    </div>

    <div
      style="background: #000"
      :style="{
        display: pageWidth >= 700 ? 'none' : 'block',
      }"
    >
      <HeaderPage></HeaderPage>
      <el-container>
        <DownLoad />
      </el-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";
import "vant/lib/index.css";
Vue.use(Popup);

import Header from "./component/Header";
import HeaderPage from "./component/HeaderPage";
import Footer from "./component/Footer";
import DownLoad from "./views/downLoad";

import "./index.scss";
export default {
  components: { DownLoad, Header, HeaderPage, Footer },
  data() {
    return {
      swiper: null,
      swiperOpen: true,

      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },

  methods: {
    showPopup() {
      this.showTop = true;
    },

    // goWheel() {
    //   if (this.swiperOpen) {
    //     if (event.deltaY > 0) {
    //       this.swiper.next();
    //     }

    //     if (event.deltaY < 0) {
    //       this.swiper.prev();
    //     }
    //   }

    //   this.swiperOpen = false;
    //     setTimeout(() => {
    //       this.swiperOpen = true;
    //     }, 1000);
    //     event.preventDefault();
    //   }
  },
};
</script>
