<template>
  <div>
    <el-header>
      <div class="header_l" @click="handleIndex">
        <img src="@/assets/logo.png" class="header_l_img" alt="" />
      </div>
      <div class="header_r">
        <img src="../../assets/san.png" alt="" @click="showPopup" />
      </div>
    </el-header>
    <van-popup v-model="show2" round closeable>
      <div style="margin: 20px 10px">
        <p style="color: #000; text-align: center">请扫码下载</p>
        <img
          style="width: 200px; height: 200px"
          src="../../assets/downEWM.apk.png"
          alt=""
        />
      </div>
    </van-popup>

    <van-popup v-model="show" position="top" closeable round>
      <img src="@/assets/logo.png" class="header_l_img" alt="" />
      <div style="margin: 0 20px 20px">
        <div class="header_b" @click="goCon('/')">
          {{ $t("download2") }}
        </div>
        <div class="header_b" @click="goCon('contactUs')">
          {{ $t("about") }}
        </div>

        <div class="header_lang">
          <p>{{ $t("language") }}</p>
          <div
            :class="localShow ? 'localShow' : 'localShow2'"
            @click="handleCommand('CN')"
          >
            {{ $t("cn") }}
            <i
              :style="{ display: !localShow ? 'none' : 'block' }"
              class="el-icon-check"
            ></i>
          </div>
          <div
            :class="!localShow ? 'localShow' : 'localShow2'"
            @click="handleCommand('English')"
          >
            English
            <i
              :style="{ display: localShow ? 'none' : 'block' }"
              class="el-icon-check"
            ></i>
          </div>
        </div>

        <div class="header-privacy" @click="goCon('/privacyAndService')">
          {{ $t("privacy") }}
        </div>
      </div>

      <div style="display: flex; justify-content: center">
        <div class="footer" @click="show2 = true">
          <img src="@/assets/18.png" alt="" />

          <p>{{ $t("download") }}</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: "CN" || "English",
      show: false,
      show2: false,
      localShow: true, // 下拉菜单语言style

      navList: [
        {
          title: "下载",
          path: "/",
        },
        {
          title: "关于我们",
          path: "/contactUs",
        },
      ],
    };
  },

  computed: {
    // 动态返回对应的路由path
    onRoutes() {
      return this.$route.path;
    },
  },

  mounted() {
    let lang = localStorage.getItem("language");
    if (lang == "zh") {
      this.$i18n.locale = localStorage.getItem("language");
      // this.localShow = !this.localShow;
    } else if (lang == "en") {
      this.$i18n.locale = localStorage.getItem("language");
      // this.localShow = !this.localShow;
    }
  },

  methods: {
    handleIndex() {
      this.$router.push("/");
    },

    goCon(conE) {
      if (this.$route.path === conE) {
        return;
      } else {
        this.$router.push(conE);
      }
    },

    handleCommand(command) {
      this.language = command;
      this.show = false;
      if (this.language == "CN") {
        localStorage.setItem("language", "zh");
        this.$i18n.locale = localStorage.getItem("language");
        this.localShow = !this.localShow;
      } else if (this.language == "English") {
        localStorage.setItem("language", "en");
        this.$i18n.locale = localStorage.getItem("language");
        this.localShow = !this.localShow;
      }
    },

    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header_l_img {
  width: 90px;
  height: 30px;
}

.el-header {
  padding-top: 10px;

  .header_r {
    img {
      width: 18px;
      height: 18px;
    }
  }
}

.van-popup--top {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header_l_img {
    margin: 30px;
  }

  ::v-deep .van-popup__close-icon--top-right {
    top: 30px;
  }

  .header_b {
    width: 100%;
    height: 70px;
    border-bottom: 1px #696969 solid;
    line-height: 70px;
    font-size: 18px;
  }

  img {
    margin-top: 20px;
  }

  .header_lang {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px #696969 solid;

    p {
      color: #a4a1a1;
      margin-bottom: 10px;
    }

    .localShow {
      color: #fff;
    }
    .localShow2 {
      color: #9d9d9d;
    }

    div {
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .header-privacy {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #ffffffb2;
    font-weight: 400;
  }

  .footer {
    position: absolute;
    bottom: 30px;

    img {
      margin-top: 0;
    }
  }
}
</style>